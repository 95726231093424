<template>
  <div class="main-box">
    <a-breadcrumb>
      <a-breadcrumb-item><a @click="$router.go(-1)">科普公益</a></a-breadcrumb-item>
      <a-breadcrumb-item style="color: #15b7dd">知识解析</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="videos">
      <div @click="toVideoPage(item.id,'知识解析详情','知识解析')" class="item-levels" v-for="item in SPVideoList" :key="item.id">
        <img class="cover" :src="item.banner||require('@/assets/image/RevisionHome/knowsImg.png')" alt="">
        <p class="name mallHidden">{{item.articleTitle}}</p>
        <div class="lable">
          <span v-for="(label,index) in fun_split(item.label)" :key="index">#{{label}}</span>
        </div>
      </div>
    </div>
    <a-pagination
      class="aPagination"
      v-model="pageNum"
      show-size-changer
      :page-size.sync="pageSize"
      :total="total"
      @showSizeChange="onShowSizeChange"
      hideOnSinglePage
    />
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      swiperOptiont: {
        slidesPerView: "auto",
        spaceBetween: 18,
        centeredSlides: false,
        centeredSlidesBounds: false,
      },
      id:0,
      detail:{}, // 视频详情
      SPVideoList:[], // 科普视频
      pageNum: 1,
      pageSize:20,
      total: 0
    }
  },
  // 事件处理器
  methods: {
    // 格式化标签
    fun_split(str){
      let arr = []
      if(!str){return arr}
      arr = str.split("，")
      return arr
    },

    toVideoPage(id, type, from){
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
      this.$router.push({
        path: "/videoDetail",
        query: {
          id: this.$AES.encode_data(id + ""),
          type,
          from
        },
      });
    },

    // 获取其他科普视频
    getClient() {
      this.$ajax({
        url: "/hxclass-pc/Article/client/list",
        params: {
          columnCode: 'Knowledge',
          queryLevel: 1,
          sortType: 1,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.SPVideoList = res.data.records
          this.total = res.data.total
        }
      });
    },
    onShowSizeChange(current, pageSize) {
      this.pageNum = current;
      this.pageSize = pageSize;
      this.getClient();
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.getClient()
   },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    pageNum(val) {
      this.getClient();
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .aPagination{
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .main-box{
    padding-top: 80px;
    padding-bottom: 50px;
    position: relative;
    .videos{
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
    }
    .item-levels{
      padding: 14px;
      width: 146px;
      min-height: 184px;
      background: #FFFFFF;
      margin-right: 42px;
      margin-bottom: 20px;
      transition: all 0.4s ease;
      cursor: pointer;
      .cover{
        width: 100%;
        height: 73px;
        margin-bottom: 12px;
        background-repeat: no-repeat;
        object-fit: cover;
        transition: transform 0.5s;
      }
      .name{
        height: 42px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
      }
      .lable{
        margin-top: 13px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #15B7DD;
        line-height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        >span{
          margin-right: 5px;
        }
      }
    }
    .item-levels:hover{
      box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
      .cover{
        transform: scale(1.05);
      }
    }
  }
</style>
